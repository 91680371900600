import { Tab, Tabs } from "@mui/material";
import { alpha, Box } from "@mui/system";
import { useCallback, useState } from "react";
import { useAuthContext } from "src/auth/hooks";
import { OrganizationTabsEnum } from "./organization-quick-edit";

type Props = {
    tabs: any[],
    onTabChange: (value: OrganizationTabsEnum) => void;
    selectedTab: OrganizationTabsEnum;
}

export default function OrganizationQuickEditTabs({ tabs, selectedTab, onTabChange }: Props) {
    const { permissions } = useAuthContext();

    const [currentTab, setCurrentTab] = useState(selectedTab);

    const handleChangeTab = useCallback((e: any, tab: number) => {
        setCurrentTab(tab);
        onTabChange(tab);
    }, []);

    return (
        <Tabs
            value={currentTab}
            sx={{
                px: 2.5,
                mr: 1,
                gap: "3px",
                button: {
                    gap: "6px"
                },
                boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                mb: 1.5
            }}
            onChange={handleChangeTab}
            >
            {tabs
            .filter((tab) => (tab.permission && permissions) ? permissions?.includes(tab.permission) : true )
            .map((tab) => (
                <Tab
                    key={tab.value}
                    iconPosition="bottom"
                    value={tab.value}
                    label={
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "3px",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            {tab.label}
                        </Box>
                    }
                    sx={{ p: "8px", cursor: "pointer" }}
                />
            ))}
        </Tabs>
    )
}