import { Dialog, DialogTitle } from "@mui/material";
import { useCallback, useState } from "react";
import { useGetOrganization } from "src/api/organization";
import { useAuthContext } from "src/auth/hooks";
import { useBoolean } from "src/hooks/use-boolean";
import { USER_PERMISSIONS } from "src/types/permissions";
import OrganizationContractView from "./organization-quick-edit-contract";
import OrganizationProfileView from "./organization-quick-edit-profile";
import OrganizationQuickEditTabs from "./organization-quick-edit-tabs";

type Props = {
    open: boolean;
    onClose: () => void;
}

export enum OrganizationTabsEnum {
    PROFILE = 1,
    CONTRACT = 2
}

export default function OrganizationQuickEdit({ open, onClose }: Props) {
    const { selectedOrganization } = useAuthContext();
    const refresh = useBoolean(open);

    const { 
        organization, isLoading
    }: any = useGetOrganization(refresh.value, selectedOrganization?.id);

    const [selectedTab, setSelectedTab] = useState(OrganizationTabsEnum.PROFILE);

    const handleChangeTab = useCallback((tab: OrganizationTabsEnum) => {
        setSelectedTab(tab);
    }, []);

    const OrganizationTabOptions = [
        {   value: OrganizationTabsEnum.PROFILE, 
            label: 'Perfil da organização', 
            component: 
            <OrganizationProfileView
                organization={organization}
                onClose={onClose}
            />
        },
        { 
            value: OrganizationTabsEnum.CONTRACT, 
            label: 'Contrato', 
            permission: USER_PERMISSIONS.ORGANIZATION_CONTRACT_VIEW, 
            component: 
            <OrganizationContractView
                organization={organization}
                onClose={onClose}
            />
        },
    ]


    if(isLoading || !organization || !organization.pixInfo){
        return <></>;
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { 
                    maxWidth: 'min-content',
                    justifyContent: 'flex-start' 
                },
            }}
        >
            <DialogTitle>
                Organização {organization.id ? ` - ${organization.id}` : ''}
            </DialogTitle>

            <OrganizationQuickEditTabs
                tabs={OrganizationTabOptions}
                onTabChange={handleChangeTab}
                selectedTab={selectedTab}
            />
            
            { OrganizationTabOptions.filter((tabs) => tabs.value === selectedTab)[0].component }

        </Dialog>
    )
}