
import { AxiosResponse } from "axios";
import { useEffect, useMemo, useState } from "react";
import { FindOrganizationConfigResponse, FindOrganizationResponse, IOrganization, PlanInfo } from "src/types/saasOrganization";
import { accessEndpoints, default as axios, default as axiosInstance } from "src/utils/axios";
import { useCustomApiFetcher } from "src/utils/fetcherCustomHook";

//--------------------------------------------------//

export function useGetOrganization(refresh: boolean, id?: number) {
    const url = accessEndpoints.organization.findById + `${id}`;
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<any>();
    const [error, setError] = useState<any>();
    
    useEffect(() => {
        setIsLoading(true);

        if(id !== -1) {
            axiosInstance(url, {
                method: `get`, 
            }).then(async (response: AxiosResponse) => {
                const data = await response.data;
                setIsLoading(false);
                setData(data);
            }).catch(async (error: any) => {
                await setError(error);
            });
        }
    }, [refresh, url]);

    const memoizedValue = useMemo(
        (): FindOrganizationResponse => ({
            organization: data ? {...data} : undefined,
            isLoading,
            error,
        }), [data, isLoading, error]
    );

    return memoizedValue;
}

export function useGetOrganizationConfig(refresh: boolean, id?: number) {
    const url = accessEndpoints.organization.config + `${id}`;

    const { data, error, isLoading } = useCustomApiFetcher(
      url,
      {},
      `get`,
      true
    );

    const memoizedValue = useMemo(
        (): FindOrganizationConfigResponse => ({
            configs: data ? data : [],
            isLoading,
            error,
        }), [data, isLoading, error]
    );

    return memoizedValue;
}

export function updateOrganization(request: IOrganization | any) {
    if(!request) return;
    const url = accessEndpoints.organization.update + `${request.id}`;

    const data = axios.put(url, request)
        .then((response) => {
            return response.data;
        });

    return data;
}

export function useGetPlanInfo(refresh: boolean) {
    const url = accessEndpoints.organization.listPlans;

    const { data, error, isLoading } = useCustomApiFetcher(
        url, {}, `get`, refresh
    );

    const memoizedValue = useMemo(
        () => ({
            planList: data ? [...data] as PlanInfo[] : [],
            isLoading,
            error,
        }), [data, isLoading, error]
    );

    return memoizedValue;

}
export function useGetRedeemDay(refresh: boolean) {
    const url = accessEndpoints.organization.redeemDay;

    const { data, error, isLoading } = useCustomApiFetcher(
        url, {}, `get`, refresh
    );

    const memoizedValue = useMemo(
        () => ({
            redeemDays: data ? [...data] : [],
            isLoading,
            error,
        }), [data, isLoading, error]
    );

    return memoizedValue;

}

export function useGetOrganizationList(refresh: boolean) {
    const url = accessEndpoints.organization.listAll;

    const { data, error, isLoading } = useCustomApiFetcher(
        url, {}, `get`, refresh
    );

    const memoizedValue = useMemo(
        () => ({
            organizationList: data ? [...data] as IOrganization[] : [] as IOrganization[],
            isLoading,
            error,
        }), [data, isLoading, error]
    );

    return memoizedValue;

}

export async function updateOrganizationConfigurations(body: any) {
    const url = accessEndpoints.organization.saveConfig;
    
    const request: any = await axios.request({
      url: url,
      method: `post`,
      data: body,
      responseType: `json`
    });
  
    if(request.status >= 400){
      throw(request.data);
    }
    
    return request.data;
  }

  export function useGetModuleOptions(refresh: boolean) {
    const url = accessEndpoints.saasOrganization.moduleOptions;

    const { data, error, isLoading } = useCustomApiFetcher(
        url, {}, `get`, refresh
    );

    const memoizedValue = useMemo(
        () => ({
            moduleOptions: data ? [...data] : [],
            isLoading,
            error,
        }), [data, isLoading, error]
    );

    return memoizedValue;

}