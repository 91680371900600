import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';
import RoleBasedPage from 'src/components/roleBasedComponent/role-based-page';
import { USER_PERMISSIONS } from 'src/types/permissions';
import { paths } from '../paths';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
// USER
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// Machine
const MachineListPage = lazy(() => import('src/pages/dashboard/machine/list'));
const MachineSendCreditPage = lazy(() => import('src/pages/dashboard/machine/sendCredit'));
const MachineEditPage = lazy(() => import('src/pages/dashboard/machine/edit'));
const MachineBonusSalePage = lazy(() => import('src/pages/dashboard/machine/sale-bonus'));
//Location
const LocationListPage = lazy(() => import ('src/pages/dashboard/locations/list'));
const LocationEditPage = lazy(() => import ('src/pages/dashboard/locations/edit'));
const LocationCreatePage = lazy(() => import ('src/pages/dashboard/locations/create'));
//Sale
const SaleListPage = lazy(() => import('src/pages/dashboard/sales/list'));
const SaleDetailsPage = lazy(() => import('src/pages/dashboard/sales/details'));
//Statement
const StatementListPage = lazy(() => import('src/pages/dashboard/statements/list'));
// Telemetry
const TelemetryListPage = lazy(() => import('src/pages/dashboard/telemetry/list'));
const TelemetryDetailsPage = lazy(() => import('src/pages/dashboard/telemetry/details'));
// Coupon
const CouponListPage = lazy(() => import('src/pages/dashboard/coupons/list'));
const CouponCreatePage = lazy(() => import('src/pages/dashboard/coupons/new'));
const CouponEditPage = lazy(() => import('src/pages/dashboard/coupons/edit'));
// Notification
const NotificationListPage = lazy(() => import('src/pages/dashboard/notification/list'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
// Visits
const VisitListPage = lazy(() => import('src/pages/dashboard/visits/list'));
const VisitCreatePage = lazy(() => import('src/pages/dashboard/visits/create'));
const VisitViewPage = lazy(() => import('src/pages/dashboard/visits/view'));
// VEND Machines
const VendMachineCreatePage = lazy(() => import('src/pages/dashboard/vend-machine/create'));
// Terminals
const TerminalDeviceListPage = lazy(() =>  import('src/pages/dashboard/terminal-device/list'));
//Announcement
const AnnouncementFeedPage = lazy(() => import('src/pages/dashboard/announcement/feed'));
const AnnouncementCreatePage = lazy(() => import('src/pages/dashboard/announcement/create'));
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path:  paths.dashboard.root,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage/>, index: true },
      {
        path: 'user',
        children: [
          { element: <RoleBasedPage permissions={[USER_PERMISSIONS.USER_VIEW]} component={<UserListPage/>}/>, index: true },
          { path: 'list', element: <RoleBasedPage permissions={[USER_PERMISSIONS.USER_VIEW]} component={<UserListPage/>}/>, },
          { path: 'new', element: <RoleBasedPage permissions={[USER_PERMISSIONS.USER_WRITE]} component={<UserCreatePage/>}/> },
          { path: ':id/edit', element: <RoleBasedPage permissions={[USER_PERMISSIONS.USER_WRITE]} component={<UserEditPage/>}/> },
        ],
      },
      {
        path: 'machine',
        children: [
          { element: <RoleBasedPage permissions={[USER_PERMISSIONS.MACHINES_VIEW]} component={<MachineListPage/>}/>, index: true },
          { path: 'list', element: <RoleBasedPage permissions={[USER_PERMISSIONS.MACHINES_VIEW]} component={<MachineListPage/>}/> },
          { path: 'manual-credit', element: <RoleBasedPage permissions={[USER_PERMISSIONS.MANUNAL_SALE]} component={<MachineSendCreditPage/>}/> },
          { path: ':id/edit', element: <RoleBasedPage permissions={[USER_PERMISSIONS.MACHINES_WRITE]} component={<MachineEditPage/>}/> },
          { path: ':id/bonus', element: <RoleBasedPage permissions={[USER_PERMISSIONS.MACHINES_BONUS_SALE]} component={<MachineBonusSalePage/>}/> },
        ],
      },
      {
        path: 'location',
        children: [
          { element: <RoleBasedPage permissions={[USER_PERMISSIONS.LOCATIONS_VIEW]} component={<LocationListPage/>}/>, index: true },
          { path: 'list', element: <RoleBasedPage permissions={[USER_PERMISSIONS.LOCATIONS_VIEW]} component={<LocationListPage/>}/> },
          { path: ':id/edit', element: <RoleBasedPage permissions={[USER_PERMISSIONS.LOCATIONS_WRITE]} component={<LocationEditPage/>}/> },
          { path: 'new', element: <RoleBasedPage permissions={[USER_PERMISSIONS.LOCATIONS_WRITE]} component={<LocationCreatePage/>}/> }
        ]
      },
      {
        path: 'sales',
        children: [
          { element: <RoleBasedPage permissions={[USER_PERMISSIONS.SALES_VIEW]} component={<SaleListPage/>}/>, index: true },
          { path: 'list', element: <RoleBasedPage permissions={[USER_PERMISSIONS.SALES_VIEW]} component={<SaleListPage/>}/> },
          { path: ':id', element: <RoleBasedPage permissions={[USER_PERMISSIONS.SALES_VIEW]} component={<SaleDetailsPage/>}/> },
        ],
      },
      {
        path: 'statements',
        children: [
          { element: <RoleBasedPage permissions={[USER_PERMISSIONS.STATEMENTS_VIEW]} component={<StatementListPage/>}/>, index: true },
          { path: 'list', element: <RoleBasedPage permissions={[USER_PERMISSIONS.STATEMENTS_VIEW]} component={<StatementListPage/>}/> }
        ],
      },
      {
        path: 'coupons',
        children: [
          { element: <RoleBasedPage permissions={[USER_PERMISSIONS.COUPON_VIEW]} component={<CouponListPage/>}/>, index: true },
          { path: 'list', element: <RoleBasedPage permissions={[USER_PERMISSIONS.COUPON_VIEW]} component={<CouponListPage/>}/> },
          { path: 'new', element: <RoleBasedPage permissions={[USER_PERMISSIONS.COUPON_TOGGLE]} component={<CouponCreatePage/>}/> },
          { path: ':id/edit', element: <RoleBasedPage permissions={[USER_PERMISSIONS.COUPON_TOGGLE]} component={<CouponEditPage/>}/> },
        ],
      },
      {
        path: 'telemetry',
        children: [
          { element: <RoleBasedPage permissions={[USER_PERMISSIONS.TELEMETRY_VIEW]} component={<TelemetryListPage/>}/>, index: true },
          { path: 'list', element: <RoleBasedPage permissions={[USER_PERMISSIONS.TELEMETRY_VIEW]} component={<TelemetryListPage/>}/> },
          { path: ':id/details', element: <RoleBasedPage permissions={[USER_PERMISSIONS.TELEMETRY_VIEW]} component={<TelemetryDetailsPage/>}/> },
        ]
      },
      {
        path: 'notifications',
        children: [
          { element: <NotificationListPage />, index: true },
          { path: 'list', element: <NotificationListPage /> }
        ]
      },
      {
        path: 'visits',
        children: [
          { element: <RoleBasedPage permissions={[USER_PERMISSIONS.VISIT_VIEW]} component={<VisitListPage/>}/>, index: true },
          { path: 'list', element: <RoleBasedPage permissions={[USER_PERMISSIONS.VISIT_VIEW]} component={<VisitListPage/>}/> },
          { path: 'create', element: <RoleBasedPage permissions={[USER_PERMISSIONS.VISIT_ADD]} component={<VisitCreatePage/>}/> },
          { path: 'view/:id', element: <RoleBasedPage permissions={[USER_PERMISSIONS.VISIT_VIEW]} component={<VisitViewPage/>}/> },
        ]
      },
      {
        path: 'terminal-device',
        children: [
          { element: <RoleBasedPage permissions={[USER_PERMISSIONS.TERMINAL_DEVICE_VIEW]} component={<TerminalDeviceListPage/>}/>, index: true },
          { path: 'list', element: <RoleBasedPage permissions={[USER_PERMISSIONS.TERMINAL_DEVICE_VIEW]} component={<TerminalDeviceListPage/>}/> },
        ]
      },
      {
        path: 'vend-machine',
        children: [
          { element: <RoleBasedPage permissions={[USER_PERMISSIONS.VEND_MACHINE_VIEW]} component={<VendMachineCreatePage/>}/>, index: true },
          { path: 'create', element: <RoleBasedPage permissions={[USER_PERMISSIONS.VEND_MACHINE_VIEW]} component={<VendMachineCreatePage/>}/> },
        ]
      },
      {
        path: 'announcements',
        children: [
          { element: <AnnouncementFeedPage/>, index: true },
          { path: 'feed', element: <AnnouncementFeedPage/> },
          { path: 'create', element: <AnnouncementCreatePage /> }
        ]
      },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
