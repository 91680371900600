import axios, { AxiosRequestConfig } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { setSession } from 'src/auth/context/jwt/utils';

import { BASE_API, BASE_ZAPIZI_API, CONSOLIDATION_API, CORE_API, EVENTS_API, HOST_API, PAYMENT_GATEWAY_API, STATEMENT_API, VEND_API } from 'src/config-global';

console.log("Iniciando chamadas do access");

console.log(HOST_API);

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    me: HOST_API + '/auth/me',
    login: HOST_API + '/auth/login',
    register: HOST_API + '/api/auth/register',
    permissions: HOST_API + '/access/permission/:organizationId',
  },
  organizations: {
    list: HOST_API + '/organization/list/user',
    removeUser: HOST_API + '/orgHasUser/remove',
    pix: HOST_API + '/organization/pix',
  },
  users: {
    list: HOST_API + '/user/list/all',
    findById: HOST_API + '/user/findById',
    update: HOST_API + '/user/update/',
    updateProfile: HOST_API + '/user/profile/update/',
    toggle: HOST_API + '/user/toggle/',
    delete: HOST_API + '/user/delete/',
    profileUpload: HOST_API + '/user/upload/profile',
    save: HOST_API + '/user/save',
    findAuthorizedByLocation: (id?: number) => `/locations/auth/list/user/${id}`
  },
  locationAuth: {
    listLocations: HOST_API + '/locations/auth/list/locations',
    listUsers: HOST_API + '/locations/auth/list/users',
  },
  role: {
    list: HOST_API + '/role/list/all',
  },

  //TODO para remover precisa remover as referencias não usadas
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};

export const coreEndpoints = {
  dashboard: {
    indicators: CORE_API + '/dashboard/indicators',
    graphDayToDay: CORE_API + '/dashboard/graph/daily',
    graphMonthToMonth: CORE_API + '/dashboard/graph/month',
    machineSales: CORE_API + '/dashboard/machines',
  },
  sales: {
    list: CORE_API + '/sales/',
    advancedSearch: CORE_API + '/sales/advancedSearch/',
    refund: CORE_API + '/sales/refund',
    manual: CORE_API + '/credit/manual',
    export: CORE_API + '/sales/export',
    details: (id: number) => CORE_API + `/sales/details/${id}`
  },
  machine: {
    list: CORE_API + '/machine/',
    listForm: CORE_API + '/machine/list/form',
    toggle: CORE_API + '/machine/toggle-active',
    findById: CORE_API + '/machine/findById/',
    update: CORE_API + '/machine/update/',
    qrcode: CORE_API + '/machine/qrcodes',
    pulseConfig: (machineId: number) => CORE_API + `/machine/configuration/${machineId}`,
    savePulseConfig: CORE_API + `/machine/configuration`,
    delete: (machineId: number) => CORE_API + `/machine/${machineId}`,
    findByQrCode: (qrCode: string) => CORE_API + `/machine/findByQrCode/${qrCode}`,
    deletionJustification: CORE_API + `/machine/deletion-justification`,
    machineBonus: (machineId: string) => CORE_API + `/machine/bonus/${machineId}`,
    updateBonus: (machineId: string) => CORE_API + `/machine/bonus/${machineId}`,
    bonusLimits: (machineId: number) => CORE_API + `/machine/bonus/limits/${machineId}`,
  },
  saasMachine: {
    list: CORE_API + '/saas/machine/',
    findById: CORE_API + '/saas/machine/findById/',
    update: CORE_API + '/saas/machine/update/',
    updateOrganization: CORE_API + '/saas/machine/organization/update/',
    testSale: PAYMENT_GATEWAY_API + '/saas/machine/test-sale',
    firmwareList: CORE_API + '/saas/machine/firmware/list',
    firmwareUpdate: CORE_API + '/saas/machine/firmware/update',
    updateOwner: CORE_API + '/saas/machine/owner/update/',
  },
  saasSales: {
    list: CORE_API + '/saas/sales/',
    refund: CORE_API + '/saas/sales/refund',
  },
  location: {
    list: CORE_API + '/location/',
    toggle: CORE_API + '/location/toggle/',
    findById: (id: number) => `${CORE_API}/location/${id}`,
    create: CORE_API + '/location',
    update: (id: number) => `${CORE_API}/location/${id}`,
  },
  saasLocation: {
    list: `${CORE_API}/saas/location/`,
  },
  coupons: {
    list: CORE_API + '/coupon/',
    toggle: CORE_API + '/coupon/toggle/',
    findById: (id: number) => `${CORE_API}/coupon/findById/${id}`,
    create: CORE_API + '/coupon',
    update: (id: number) => `${CORE_API}/coupon/${id}`,
    public: {
      findByReferenceId: (refrenceId: string) => `${CORE_API}/public/coupon/findByReferenceId/${refrenceId}`,
      use: (id: number) => `${CORE_API}/public/coupon/use/${id}`,
    }
  },
  telemetry: {
    list: CORE_API + '/machine-io/list/summarized',
    details: (machineId: number) => CORE_API + `/machine-io/list/details/${machineId}`,
    export: CORE_API + '/machine-io/export/summarized',
    exportDetails: (machineId: number) => CORE_API + `/machine-io/export/details/${machineId}`,
    advancedSearch: CORE_API + '/machine-io/advancedSearch'
  },
  configuration: {
    findByKey: CORE_API + '/configuration/',
  },
  templates: {
    list: CORE_API + '/templates'
  },
  machineIO: {
    creditCommand: {
      list: CORE_API + '/machine-io/list/credit',
    },
    productCommand: {
      list: CORE_API + '/machine-io/list/product',
    }
  },
  visits: {
    list: CORE_API + '/visit',
    findLatest: CORE_API + '/visit/latest',
    save: CORE_API + '/visit/',
    findById: (id: number) => CORE_API + `/visit/findById/${id}`,
  },
  terminalDevice: {
    list: CORE_API + '/terminalDevice',
  },
  saasTerminalDevice: {
    list: CORE_API + '/saas/terminalDevice',
    find: (id: number) =>  CORE_API + `/saas/terminalDevice/details/${id}`,
    create: CORE_API + '/saas/terminalDevice',
    definitions: CORE_API + '/saas/terminalDevice/definitions',
    bindOrganization: CORE_API + '/saas/terminalDevice/bindOrganization',
  },
};

export const statementEndpoints = {
  statement: {
    list: STATEMENT_API + '/statements/',
    redeem: STATEMENT_API + '/statements/redeem',
  },
  saasStatement: {
    list: STATEMENT_API + '/saas/statements/',
    redeem: STATEMENT_API + '/saas/statements/redeem',
  },
  pix: {
    getKey: STATEMENT_API + '/pix/key',
    create: STATEMENT_API + '/pix/',
  },
  consolidation: {
    list: STATEMENT_API + '/consolidation/',
    consolidate: STATEMENT_API + '/consolidation/consolidate',
    run: STATEMENT_API + '/consolidation/run',
  },
};

export const consolidationEndpoints = {
  consolidation: {
    list: BASE_ZAPIZI_API + CONSOLIDATION_API +  '/consolidation/list',
    details: (id: number) => BASE_ZAPIZI_API + CONSOLIDATION_API +  `/consolidation/${id}`,
    manual: BASE_ZAPIZI_API + CONSOLIDATION_API +  '/consolidation/manual',
  },
};

export const vendEndpoints = {
  vend: {
    models: VEND_API + '/machine/models',
    get: VEND_API + '/machine',
    create: VEND_API + '/machine',
    update: VEND_API + '/machine',
    delete: VEND_API + '/machine',
    button : {
      refill:  VEND_API + '/machine/button/refill',
      cleanup:  VEND_API + '/machine/button/cleanup',
    }
  },
};

export const accessEndpoints = {
  organization: {
    findById: `${HOST_API}/organization/findById/`,
    update: `${HOST_API}/organization/update/`,
    create: `${HOST_API}/organization/save`,
    listPlans: `${HOST_API}/organization/list/plan`,
    listAll: `${HOST_API}/organization/list/all`,
    redeemDay: `${HOST_API}/redeem/options`,
    contract: `${HOST_API}/contract/organization`,
    signContract: `${HOST_API}/contract/organization/sign`,
    config: `${HOST_API}/config/organization/`,
    saveConfig: `${HOST_API}/config/organization/save`,
  },
  saasOrganization: {
    list: `${HOST_API}/saas/organization/list`,
    findById: `${HOST_API}/saas/organization/findById/`,
    update: `${HOST_API}/saas/organization/update/`,
    create: `${HOST_API}/saas/organization/save`,
    findContractById: `${HOST_API}/contract/organization/`,
    updateContract: `${HOST_API}/contract/organization/update`,
    moduleOptions: `${HOST_API}/saas/organization/module/options`,
  },
  aws: {
    presignedUrl: `${HOST_API}/aws/presignedUrl`,
  },
  locationAuth: {
    listLocations: HOST_API + '/locations/auth/list/locations',
    listUsers: HOST_API + '/locations/auth/list/users',
  },
  auth: {
    me: HOST_API + '/auth/me',
    login: HOST_API + '/auth/login',
    register: HOST_API + '/api/auth/register',
    permissions: HOST_API + '/access/permission/:organizationId',
    refresh: HOST_API + '/access/token/refresh',
    forgot: HOST_API + '/auth/forgotPassword',
    reset: (token: string) => `${HOST_API}/auth/forgotPassword/${token}`,
    changePassword: HOST_API + '/auth/changePassword',
  },
  organizations: {
    list: HOST_API + '/organization/list/user',
    removeUser: HOST_API + '/orgHasUser/remove',
    pix: HOST_API + '/organization/pix',
  },
  users: {
    list: HOST_API + '/user/list/all',
    findById: HOST_API + '/user/findById',
    update: HOST_API + '/user/update/',
    updateProfile: HOST_API + '/user/profile/update/',
    toggle: HOST_API + '/user/toggle/',
    delete: HOST_API + '/user/delete/',
    profileUpload: HOST_API + '/user/upload/profile',
    save: HOST_API + '/user/save',
    searchDocumentUser: HOST_API + '/user/search_document',
    findAuthorizedByLocation: (id?: number) => `${HOST_API}/locations/auth/list/user/${id}`,
    listSaas: HOST_API + '/user/list/saas',
  },
  role: {
    list: HOST_API + '/role/list/all',
  },
};

export const eventEndpoints = {
  contact: {
    saveToken: `${EVENTS_API}/contact/token/`,
  },
  event: {
    listAll: `${EVENTS_API}/event/`,
    findById: (id: number) => `${EVENTS_API}/event/${id}`
  },
  announcement: {
    save: `${EVENTS_API}/announcements/`,
    list: `${EVENTS_API}/announcements/`,
    banner: `${EVENTS_API}/announcements/banner`
  }
};

export class IRequestFormError {
  message: string;
  statusCode: number;
  formField?: any;
  constructor(){
    this.message = "Erro"
    this.statusCode = 400
  }
}

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: BASE_API });

const publicPaths = [
  accessEndpoints.auth.login
];

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosInstance.interceptors.response.use(
  (res) => res,
  async (error) => {
    console.log(error);
    const originalRequest = error.config;
    const isUrlPublic = originalRequest.url !in publicPaths;

    if (error.response && error.response.status === 401 && !originalRequest._retry && !isUrlPublic) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        }).then((response: any) => {
          originalRequest.headers['Authorization'] = 'Bearer ' + response.token;
          return axiosInstance(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        axiosInstance.defaults.headers['x-refresh-token'] = sessionStorage.getItem("refreshToken");
        axiosInstance.post(accessEndpoints.auth.refresh).then((res) => {
          const accessToken = res.data.token;
          const refreshToken = res.data.refreshToken;
          setSession(accessToken, refreshToken);
          originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
          processQueue(null, res.data);
          resolve(axiosInstance(originalRequest));
        }).catch((err) => {
          window.location.href = '/';
          setSession(undefined, undefined);
          processQueue(err, null);
          reject(err);
        }).finally(() => {
          isRefreshing = false;
        });
      });
    }
    
    try {
      if(error.response){
        const response = error.response;
        enqueueSnackbar(response?.data?.message, { variant: 'error' });
      }else{
        enqueueSnackbar('Ops! Encontramos um erro!', { variant: 'error' });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Ops! Encontramos um erro!', { variant: 'error' });
    }
    
    return Promise.reject(error);
  }
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [any, AxiosRequestConfig]) => {
  const [data, config] = Array.isArray(args) ? args : [args];
  
  const res = await axiosInstance.request({
    url: data.url,
    method: data.method,
    params: data.params,
    data: data.body,
  });
  
  return res.data;
};

// ----------------------------------------------------------------------