import { useMemo } from "react";

import { paths } from "src/routes/paths";

import { useTranslate } from "src/locales";

import { useAuthContext } from "src/auth/hooks";
import Iconify from "src/components/iconify";
import SvgColor from "src/components/svg-color";
import { USER_PERMISSIONS } from "src/types/permissions";

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon("ic_job"),
  blog: icon("ic_blog"),
  chat: icon("ic_chat"),
  mail: icon("ic_mail"),
  user: icon("ic_user"),
  file: icon("ic_file"),
  lock: icon("ic_lock"),
  tour: icon("ic_tour"),
  order: icon("ic_order"),
  label: icon("ic_label"),
  blank: icon("ic_blank"),
  kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  invoice: icon("ic_invoice"),
  product: icon("ic_product"),
  calendar: icon("ic_calendar"),
  disabled: icon("ic_disabled"),
  external: icon("ic_external"),
  menuItem: icon("ic_menu_item"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
  coupon: icon("ic_coupon"),
  machine: <Iconify icon="mdi:coffee-machine" />,
  telemetry: <Iconify icon="carbon:meter"/>,
  visits: <Iconify icon="zondicons:travel-taxi-cab"/>,
  notifications: <Iconify icon="ion:notifications"/>,
  announcement: <Iconify icon="solar:volume-bold"/>,
  creditCard: <Iconify icon="solar:card-2-linear"/>,
};

// ----------------------------------------------------------------------

export function useNavData() {
  const refresh = false;

  const { t } = useTranslate();

  const { user } = useAuthContext();

  const data = useMemo(
    () => [
      {
        subheader: t("menu"),
        enabled: true,
        items: [
          {
            title: t("Dashboard"),
            // roles: ["admin", "mannager"],
            permissions: [],
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
            onlyAdmin: false,
          },
          {
            title: "Comunicados",
            // roles: ["admin", "mannager"],
            permissions: [],
            path: paths.dashboard.announcements.feed,
            icon: ICONS.announcement,
            onlyAdmin: false,
          },
          {
            title: t("sales"),
            path: paths.dashboard.sales.list,
            icon: ICONS.analytics,
            permissions: [USER_PERMISSIONS.SALES_VIEW],
            onlyAdmin: false,
          },
          {
            title: "Telemetria",
            path: paths.dashboard.telemetry.list,
            icon: ICONS.telemetry,
            permissions: [USER_PERMISSIONS.TELEMETRY_VIEW],
            onlyAdmin: false,
          },
          {
            title: t("machines"),
            path: paths.dashboard.machine.list,
            icon: ICONS.machine,
            permissions: [USER_PERMISSIONS.MACHINES_VIEW],
            onlyAdmin: false,
          },
          {
            title: t("locations"),
            path: paths.dashboard.location.list,
            icon: ICONS.ecommerce,
            permissions: [USER_PERMISSIONS.LOCATIONS_VIEW],
            onlyAdmin: false,
          },
          {
            title: t("user"),
            path: paths.dashboard.user.list,
            icon: ICONS.user,
            permissions: [USER_PERMISSIONS.USER_VIEW],
            onlyAdmin: false,
          },
          {
            title: t("statements"),
            path: paths.dashboard.statements.list,
            icon: ICONS.invoice,
            permissions: [USER_PERMISSIONS.STATEMENTS_VIEW],
            onlyAdmin: true,
          },
          {
            title: t("coupons"),
            path: paths.dashboard.coupons.list,
            icon: ICONS.coupon,
            permissions: [USER_PERMISSIONS.COUPON_VIEW],
            onlyAdmin: false,
          },
          {
            title: "Notificações",
            path: paths.dashboard.notifications.root,
            icon: ICONS.notifications,
            permissions: [USER_PERMISSIONS.MACHINES_VIEW],
            onlyAdmin: false,
          },
          {
            title: "Visitas",
            path: paths.dashboard.visits.list,
            icon: ICONS.visits,
            permissions: [USER_PERMISSIONS.VISIT_VIEW],
            onlyAdmin: false,
          },
          {
            title: t("terminals"),
            path: paths.dashboard.terminalDevice.list,
            icon: ICONS.creditCard,
            permissions: [USER_PERMISSIONS.TERMINAL_DEVICE_VIEW],
            onlyAdmin: false,
          },
        ],
      }
    ],
    [t]
  );

  return data;
}
