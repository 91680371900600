import { Button, Card, CardContent, CardHeader, DialogActions, DialogContent, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useResponsive } from "src/hooks/use-responsive";
import { ISaasOrganization } from "src/types/saasOrganization";

type Props = {
    organization: ISaasOrganization;
    onClose: () => void;
};

export default function OrganizationContractView({ organization, onClose }: Props) {
    const lg = useResponsive("up", "sm");

    if (!organization || !organization.pixInfo) {
        return <></>;
    }

    return (
        <>
            <DialogContent>
                <Stack
                    direction="column"
                    spacing={4}
                    justifyContent="start"
                    flexWrap={lg ? "nowrap" : "wrap"}
                >
                    <Stack
                        direction={lg ? "row" : "column"}
                        gap={2}
                    >
                        <Card sx={{ width: "450px", flex: 1 }}>
                            <CardHeader title="Plano Atual" />
                            <CardContent>
                                <Typography variant="h6">{organization.planInfo.description}</Typography>
                            </CardContent>
                        </Card>

                        <Card sx={{ width: "450px", flex: 1 }}>
                            <CardHeader title="Dia de Resgate" />
                            <CardContent>
                                <Typography variant="h6">{organization.redeemDay?.optionDescription}</Typography>
                            </CardContent>
                        </Card>
                    </Stack>

                    {/* Tabela de Taxas Aplicáveis */}
                    <Card sx={{ width: "100%", flex: 2 }}>
                        <CardHeader title="Taxas Aplicáveis" />
                        <CardContent>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>Descrição</strong></TableCell>
                                            <TableCell><strong>Valor</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {organization.planInfo.fees.map((fee, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{fee.description}</TableCell>
                                                <TableCell>{fee.value}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                    
                </Stack>
            </DialogContent>
            
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Cancelar
                </Button>
            </DialogActions>
        </>
    );
}
