export function hideEmail(email: string): string {
    if(!email){
        return "";
    }

    if(!email.includes('@')){
        return '*'.repeat(email.length);
    }

    email = email.toLocaleLowerCase();
    email = email.trim();

    const [username, domain] = email.split('@');
    const hiddenUsername = username.slice(0, 3) + '*'.repeat(username.length - 3);

    if(domain.length < 5){
        return `${hiddenUsername}@${domain}`;
    }

    const hiddenDomain = domain.charAt(0) + '*'.repeat(domain.length - 5) + domain.slice(-4);

    return `${hiddenUsername}@${hiddenDomain}`;
}